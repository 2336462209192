import { initialState } from './initialState';
import { LoadingStatusEnum } from '../../../constants/enums';

export const GetTobaccosContainersReducer = {
  pending: (state) => ({
    ...state,
    containers: {
      ...state.containers,
      loading: LoadingStatusEnum.Pending,
    }
  }),
  fulfilled: (state, action) => ({
    ...state,
    containers: {
      list: action.payload.data,
      loading: LoadingStatusEnum.Succeeded,
    }
  }),
  rejected: (state) => ({
    ...state,
    containers: {
      ...state.containers,
      loading: LoadingStatusEnum.Failed,
    }
  }),
};

export const CanDeleteTobaccosContainersReducer = {
  pending: (state) => ({
    ...state,
    canDelete: {
      ...state.canDelete,
      loading: LoadingStatusEnum.Pending,
    }
  }),
  fulfilled: (state, action) => ({
    ...state,
    canDelete: {
      data: action.payload.data.canDelete,
      loading: LoadingStatusEnum.Succeeded,
    }
  }),
  rejected: (state) => ({
    ...state,
    canDelete: {
      ...state.canDelete,
      loading: LoadingStatusEnum.Failed,
    }
  }),
};

export const ClearCanDeleteInformationReducer = (state) => ({
  ...state,
  canDelete: initialState.canDelete,
});

export const GetTobaccoContainersRoomsReducer = {
  pending: (state) => ({
    ...state,
    rooms: {
      ...state.rooms,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    rooms: {
      list: action.payload.data || [],
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    rooms: {
      ...state.rooms,
      loading: 'failed',
    },
  }),
};

export const DeleteTobaccoContainerReducer = {
  pending: (state) => ({
    ...state,
    containers: {
      ...state.containers,
      loading: 'pending',
    }
  }),
  fulfilled: (state, action) => {
    const { id } = action.payload;

    const filteredList = state.list?.map(({ subitems, ...rest }) => ({
      ...rest,
      subitems: subitems?.filter((item) => item._id !== id),
    }));

    return {
      ...state,
      containers: {
        list: filteredList,
        loading: 'succeeded',
      }
    };
  },
  rejected: (state) => ({
    ...state,
    containers: {
      ...state.containers,
      loading: 'failed',
    }
  }),
};

export const GetTobaccoTransactionsReducer = {
  pending: (state) => ({
    ...state,
    tobaccoTransactions: {
      ...state.tobaccoTransactions,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    tobaccoTransactions: {
      list: action.payload.data.list || [],
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    tobaccoTransactions: {
      ...state.tobaccoTransactions,
      loading: 'failed',
    },
  }),
};

export const ClearTobaccoTransactionsReducer = (state) => ({
  ...state,
  tobaccoTransactions: initialState.tobaccoTransactions,
});

export const GetAllConveyorStepRecordsReducer = {
  pending: (state) => ({
    ...state,
    allConveyorStepRecords: {
      ...state.allConveyorStepRecords,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    allConveyorStepRecords: {
      list: action.payload.data || [],
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    allConveyorStepRecords: {
      ...state.allConveyorStepRecords,
      loading: 'failed',
    },
  }),
};

export const ClearAllConveyorStepRecordsReducer = (state) => ({
  ...state,
  allcConveyorStepRecords: initialState.allConveyorStepRecords,
});

export const GetConveyorStepRecordsReducer = {
  pending: (state) => ({
    ...state,
    conveyorStepRecords: {
      ...state.conveyorStepRecords,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    conveyorStepRecords: {
      list: action.payload.data.list || [],
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    conveyorStepRecords: {
      ...state.conveyorStepRecords,
      loading: 'failed',
    },
  }),
};

export const ClearConveyorStepRecordsReducer = (state) => ({
  ...state,
  conveyorStepRecords: initialState.conveyorStepRecords,
});

export const GetTobaccoContainerByIdReducer = {
  pending: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedItem: {
        data,
        loading: 'succeeded',
      },
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'failed',
    },
    loading: 'failed',
  }),
};

export const UpdateTobaccoContainerReducer = {
  pending: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedItem: {
        data,
        loading: 'succeeded',
      },
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'failed',
    },
    loading: 'failed',
  }),
};

export const GetTobaccoContainerStepsReducer = {
  pending: (state) => ({
    ...state,
    steps: {
      ...state.steps,
      loading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      steps: {
        list: data.list,
        loading: 'succeeded',
      },
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    steps: {
      ...state.steps,
      loading: 'failed',
    },
    loading: 'failed',
  }),
};

export const GetTobaccoContainerTobaccoTypesReducer = {
  pending: (state) => ({
    ...state,
    tobaccoTypes: {
      ...state.tobaccoTypes,
      loading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      tobaccoTypes: {
        list: data,
        loading: 'succeeded',
      },
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    tobaccoTypes: {
      ...state.tobaccoTypes,
      loading: 'failed',
    },
    loading: 'failed',
  }),
};