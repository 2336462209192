import { Container, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Page from '../../components/Page';
import QRCodeData from './DataSections/QRCodeData';
import TobaccoContainerData from './DataSections/TobaccoContainerData';

// ----------------------------------------------------------------------

const TobaccoContainerQRCode = ({ sx, qrCode }) => {
  const { t } = useTranslation();

  return (
    <Page py={12} title={t('pages.qrCodeInfo', { value: t(`qrCodeType.${qrCode.type}`) })}>
      <Container maxWidth={'md'} sx={sx}>
        <Stack direction="column" spacing={2}>
          <Typography variant="h4" gutterBottom>
            {t('pages.qrCodeInfo', { value: t(`qrCodeType.${qrCode.type}`) })}
          </Typography>
          <QRCodeData data={qrCode} />
          <TobaccoContainerData data={qrCode.tobaccoContainer} />
        </Stack>
      </Container>
    </Page>
  );
};

TobaccoContainerQRCode.propTypes = {
  sx: PropTypes.object,
  qrCode: PropTypes.object,
};

export default TobaccoContainerQRCode;
