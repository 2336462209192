import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DataCardRow, DataCardSection } from '../../../components/DataCardSection';

// -------------------------------------------------------------------------

const QRCodeData = ({ data }) => {
  const { t } = useTranslation();

  const { qrId, isPrinted, type } = data || {};

  if (!data) return null;

  return (
    <DataCardSection title={t('qrCodeInfoPage.qrCode.sectionTitle.qrData')}>
      <DataCardRow
        title={t('qrCodeInfoPage.qrCode.qrId.title')}
        text={qrId}
        titleIconProps={{ icon: 'ion:qr-code-outline' }}
      />
      <DataCardRow
        title={t('qrCodeInfoPage.qrCode.type.title')}
        text={t(`qrCodeType.${type}`)}
        titleIconProps={{ icon: 'ion:text-outline' }}
      />
      <DataCardRow
        title={t('qrCodeInfoPage.qrCode.isPrinted.title')}
        text={isPrinted ? t('qrCodeInfoPage.yes') : t('qrCodeInfoPage.no')}
        titleIconProps={{ icon: 'ion:print-outline' }}
        textIconProps={{
          color: isPrinted ? 'success.main' : 'error.main',
          icon: isPrinted ? 'carbon:checkmark-outline' : 'carbon:close-outline',
        }}
      />
    </DataCardSection>
  );
};

QRCodeData.propTypes = {
  data: PropTypes.object.isRequired,
};

export default QRCodeData;
