import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mui/material';
import { endOfWeek, startOfWeek, format } from 'date-fns';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { memo, useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import useFormPersist from 'react-hook-form-persist';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FormProvider } from '../../../components/hook-form';
import WeekPicker from '../../../components/hook-form/forms/WeekPicker';
import ToastEmitter from '../../../components/toaster/ToastEmmited';
import { useAuth } from '../../../providers/AuthProvider';

// --------------------------------------------------------------------------

const getWeekEnd = (startDate, weekStartsOn = 0) => {
  const weekEnd = new Date(startDate);
  return endOfWeek(weekEnd.setDate(weekEnd.getDate() + 1), { weekStartsOn }).toISOString();
};

const COMPONENT_KEY = 'TOBACCO_PRODUCTION_DEFAULT_FILTERS';

const TobaccoProductionDefualtFilters = ({ onSubmit, weekStartsOn = 0, page, getData }) => {
  const { t } = useTranslation();

  const startDate = startOfWeek(new Date(), { weekStartsOn: weekStartsOn > 0 ? weekStartsOn - 1 : 0 }).toISOString();
  const endDate = getWeekEnd(startDate, weekStartsOn);

  // default values
  const defaultValues = {
    startDate,
    endDate,
  };

  // validation Schema
  const schema = yup.object().shape({
    startDate: yup.date().typeError(t('validation.required')).required(t('validation.required')),
    endDate: yup.date().typeError(t('validation.required')).required(t('validation.required')),
  });

  const methods = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { handleSubmit, control, setValue, watch } = methods;

  const startDateWatch = useWatch({ name: 'startDate', control, defaultValue: defaultValues.startDate });
  const endDateWatch = useWatch({ name: 'endDate', control, defaultValue: defaultValues.endDate });

  // local storage key
  const { localStoragePrefix } = useAuth();
  const LOCAL_STORAGE_FORM_DATA_KEY = `${localStoragePrefix}_${page}_${COMPONENT_KEY}`;

  useFormPersist(LOCAL_STORAGE_FORM_DATA_KEY, {
    watch,
    setValue,
    storage: window.localStorage,
  });

  useEffect(() => {
    if(getData) {
      getData({startDate: watch().startDate, endDate: watch().endDate});
    }
  }, [getData, watch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmitCallback = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);
  const debouncedOnSubmit = useMemo(() => debounce(handleSubmitCallback, 500), [handleSubmitCallback]);

  // submit form when values change
  useEffect(() => {
    debouncedOnSubmit();
  }, [debouncedOnSubmit, startDateWatch, endDateWatch]);

  const onChangeWeek = (date) => {
    const newStartDate = new Date(date);
    setValue('startDate', newStartDate.toISOString());

    const newEndDate = getWeekEnd(newStartDate, weekStartsOn);
    setValue('endDate', newEndDate);
  };
  const formattedStartDate = format(new Date(startDateWatch), 'MM/dd/yyyy');
  const formattedEndDate = format(new Date(endDateWatch), 'MM/dd/yyyy');

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit, (error) => ToastEmitter.notify(`Error: ${error}`, 'error'))}
    >
      <Stack
        spacing={2}
        direction={{ xs: 'column', sm: 'row' }}
        alignItems="flex-start"
        justifyContent={'space-between'}
        mb={2}
      >
        <WeekPicker onChange={onChangeWeek} value={startDateWatch} weekStartsOn={weekStartsOn} />
        <Box>
          {formattedStartDate} - {formattedEndDate}
        </Box>
      </Stack>
    </FormProvider>
  );
};

TobaccoProductionDefualtFilters.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  weekStartsOn: PropTypes.number.isRequired,
  page: PropTypes.string.isRequired,
  getData: PropTypes.func,
};

export default memo(TobaccoProductionDefualtFilters);
