import { createSlice } from '@reduxjs/toolkit';
import { addExtraReducer } from '../../utils/addExtraReducer';
import initialState from './initialState';
import { GetConveyorsMenuReducer } from './reducers';
import { GetConveyorsMenu } from './thunks';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'conveyorsMenu',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addExtraReducer(builder, GetConveyorsMenu, GetConveyorsMenuReducer);
  },
});

export default slice.reducer;
