import { axiosApiInstance } from './axios';

import ToastEmitter from '../components/toaster/ToastEmmited';

// -------------------------------------------------------------

const apiRoot = '';

const qrCodesApi = {
  // CREATE
  create: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/qr`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET
  getByQrId: async (params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/qr/${params.factory}/${params.qrId}`);

      if (res.data && res.data.status === 'redirect' && res.data.redirectTo) {
        window.location.href = res.data.redirectTo;
        return null;
      }
      return res;
    } catch (e) {
      return e.response;
    }
  },

  getByQrIdWithoutFactory: async (qrId) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/qr/${qrId}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getInfo: async (params) => {
    try {
      const timeoutInMillis = 1000 * 60 * 5; // 5 minutes
      const res = await axiosApiInstance.get(`${apiRoot}/qr/info`, { params, timeout: timeoutInMillis });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET PDF
  getPdf: async (data) => {
    try {
      const timeoutInMillis = 1000 * 60 * 20; // 20 minutes
      axiosApiInstance.defaults.timeout = timeoutInMillis;
      const { qrCodes, qrPdfTemplateId } = data;
      const res = await axiosApiInstance.put(
        `${apiRoot}/qr/pdf`,
        { qrCodes },
        {
          responseType: 'arraybuffer',
          timeout: timeoutInMillis,
          params: {
            qrPdfTemplateFactoryOption: qrPdfTemplateId,
          },
        }
      );

      return res;
    } catch (e) {
      ToastEmitter.notify(`Error fetching PDF: ${e}`, 'error');
      return e.response;
    }
  },

  getBusinessCardsPdf: async (data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/qr/cards-pdf`, data, { responseType: 'arraybuffer' });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  updateIsPrintedAndDownloaded: async (data) => {
    try {
      const res = await axiosApiInstance.put(`/qr/updateIsPrintedAndDownloaded`, data);

      return res.data;
    } catch (e) {
      throw e.response;
    }
  },
};

export default qrCodesApi;
