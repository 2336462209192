import { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, RHFAutocomplete, RHFTextField } from '../../../components/hook-form';
import selectors from '../../../redux/reducers/tobaccoProductionCorrection/selectors';
import { CreateCorrectionLog } from '../../../redux/reducers/tobaccoProductionCorrection/thunks';

export default function ConveyorTransactionsCorrectionForm({ correctionModalInfo, handleCloseCorrectionModal, page, refreshData }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const methods = useForm();

  const { handleSubmit, control, isSubmitting, setValue } = methods;

  const reasons = useSelector(selectors.correctionReasons.list);

  const selectedReason = useWatch({ name: 'reason', control });
  const selectedNotes = useWatch({ name: 'notes', control });

  const handleSubmitCorrectionModal = async () => {
    const res = await dispatch(
      CreateCorrectionLog({
        page,
        reasonType: selectedReason,
        notes: selectedNotes,
        tobaccoTransaction: correctionModalInfo?._id,
      })
    );

    if (res.payload?.status === 'success') {
      refreshData();
      handleCloseCorrectionModal();
    }
  };

  useEffect(() => {
    const notesInReasons = reasons.some(reason => reason.description === selectedNotes);

    if (!selectedNotes) {
      setValue('notes', selectedReason?.description); 
    } else if (notesInReasons) {
      setValue('notes', selectedReason?.description); 
    }

    // eslint-disable-next-line
  }, [selectedReason, reasons]);

  return (
    <Box>
      <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitCorrectionModal)}>
        <Grid container spacing={2}>
          <Grid item container xs={12} md={12} spacing={2}>
            <Grid item xs={12} sm={6} md={12}>
              <RHFAutocomplete
                options={reasons}
                getOptionLabel={(item) => item?.value}
                name={'reason'}
                label={t('label.reason')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <RHFTextField name={'notes'} placeholder={t('label.notes')} multiline rows={4} />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Stack alignItems={'flex-end'} direction={'column'}>
              <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
                {t('button.add')}
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </FormProvider>
    </Box>
  );
}

ConveyorTransactionsCorrectionForm.propTypes = {
  correctionModalInfo: PropTypes.object.isRequired,
  handleCloseCorrectionModal: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  refreshData: PropTypes.func,
};
