import { Table, TableBody, TableRow } from '@mui/material';
import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StyledModal from '../../../../components/StyledModal';
import { applySortFilter, getComparator } from '../../../../utils/tableUtils';

import { TableCell, TableHead } from '../../table';

const getTableHead = (t) => [
  { id: 'reasonType.value', label: t('table.reason'), alignRight: false },
  { id: 'notes', label: t('table.notes'), alignRight: false },
  { id: 'createdBy.fullName', label: t('table.createdBy'), alignRight: false },
  { id: 'createdAt', label: t('table.created'), alignRight: false },
];

const getFilteredItem = (item, t) => ({
  reason: item.reasonType.value,
  notes: item.notes,
  createdBy: item.createdBy.fullName,
  createdAt: t('date.shortWithTime', { date: parseISO(item?.createdAt) }),
});

export default function TobaccoCorrectionHistoryModal({ modalIsOpen, handleCloseModal, corrections }) {
  const { t } = useTranslation();

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdAt');

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filteredList = corrections && applySortFilter(corrections, getComparator(order, orderBy), (item) => getFilteredItem(item, t));

  return (
    <StyledModal maxWidth={'md'} title={t(`label.correctionHistory`)} open={modalIsOpen} handleClose={handleCloseModal}>
      <Table>
        <TableHead
          order={order}
          orderBy={orderBy}
          headLabel={getTableHead(t)}
          rowCount={corrections?.length}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {filteredList?.map((item) => (
            <TableRow key={item?._id}>
              <TableCell>{item?.reasonType?.value}</TableCell>
              <TableCell>{item?.notes}</TableCell>
              <TableCell>{item?.createdBy?.fullName}</TableCell>
              <TableCell>{t('date.shortWithTime', { date: parseISO(item?.createdAt) })}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledModal>
  );
}

TobaccoCorrectionHistoryModal.propTypes = {
  corrections: PropTypes.array,
  modalIsOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};
