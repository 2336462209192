import { LoadingStatusEnum } from '../../../constants/enums';

// ----------------------------------------------------------------

export default {
  correctionReasons: {
    list: (state) => state.tobaccoProductionCorrection.correctionReasons.data,
    loading: (state) => state.tobaccoProductionCorrection.correctionReasons.loading,
    isLoading: (state) => state.tobaccoProductionCorrection.correctionReasons.loading === LoadingStatusEnum.Pending,
  }
};
