import { createAsyncThunk } from '@reduxjs/toolkit';
import { tobaccoContainersApi } from '../../../api/tobaccoContainersApi';
import tobaccoInventoryApi from '../../../api/tobaccoInventoryApi';
import roomsApi from '../../../api/roomsApi';
import tobaccoProductionsApi from '../../../api/tobaccoProductionsApi';
import factoryOptionsApi from '../../../api/factoryOptionsApi';

const REDUCER_NAME = 'tobaccoContainers';

export const GetTobaccosContainers = createAsyncThunk(
  `${REDUCER_NAME}/getTobaccoContainers`,
  async ({ fromDate, toDate, query }, thunkAPI) => {
    const data = { fromDate, toDate, ...query };
    const res = await tobaccoContainersApi.get(data);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const CanDeleteTobaccoContainer = createAsyncThunk(
  `${REDUCER_NAME}/canDeleteTobaccoContainers`,
  async (id, thunkAPI) => {
    const res = await tobaccoContainersApi.canDelete(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetTobaccosContainersRooms = createAsyncThunk(
  `${REDUCER_NAME}/getTobaccosContainersRooms`,
  async (__, thunkAPI) => {
    const res = await roomsApi.getTobaccoInventoryRooms();

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const DeleteTobaccoContainer = createAsyncThunk(
  `${REDUCER_NAME}/deleteTobaccoContainer`,
  async (id, thunkAPI) => {
    const res = await tobaccoInventoryApi.delete(id);

    if (res?.status === 200) return { id };

    return thunkAPI.rejectWithValue(null);
  }
);

export const GetTobaccoTransactions = createAsyncThunk(
  `${REDUCER_NAME}/getTobaccoTransactions`,
  async (id, thunkAPI) => {
    const res = await tobaccoInventoryApi.getTobaccoTransactions(id);
    if (res?.status === 200) return res.data;

    return thunkAPI.rejectWithValue(null);
  }
);

export const GetConveyorStepRecords = createAsyncThunk(
  `${REDUCER_NAME}/getConveyorStepRecords`,
  async (id, thunkAPI) => {
    const res = await tobaccoInventoryApi.getConveyorStepRecords(id);

    if (res?.status === 200) return res.data;

    return thunkAPI.rejectWithValue(null);
  }
);

export const GetAllConveyorStepRecords = createAsyncThunk(
  `${REDUCER_NAME}/getAllConveyorStepRecords`,
  async (id, thunkAPI) => {
    const res = await tobaccoInventoryApi.getAllConveyorStepRecords(id);

    if (res?.status === 200) return res.data;

    return thunkAPI.rejectWithValue(null);
  }
);

export const GetTobaccoContainerById = createAsyncThunk(
  `${REDUCER_NAME}/getTobaccoContainer`,
  async (id, thunkAPI) => {
    const res = await tobaccoInventoryApi.getById(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const UpdateTobaccoContainer = createAsyncThunk(
  `${REDUCER_NAME}/updateTobaccoContainer`,
  async ({ data, id }, thunkAPI) => {
    const res = await tobaccoInventoryApi.update(data, id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetTobaccoContainerSteps = createAsyncThunk(
  `${REDUCER_NAME}/getTobaccoContainersSteps`,
  async (__, thunkAPI) => {
    const res = await tobaccoProductionsApi.getTobaccoConveyorSteps();

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetTobaccoContainerTobaccoTypes = createAsyncThunk(
  `${REDUCER_NAME}/getTobaccoContainersTobaccoTypes`,
  async (__, thunkAPI) => {
    const res = await factoryOptionsApi.getByName('tobaccoTypes');

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);