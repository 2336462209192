import { LoadingStatusEnum } from '../../../constants/enums';

// ----------------------------------------------------------------

export default {
  list: (state) => state.conveyorTransactions.list,
  corrections: (state) => state.conveyorTransactions.corrections,
  configs: (state) => state.conveyorTransactions.configs,
  loading: (state) => state.conveyorTransactions.loading,
  isLoading: (state) => state.conveyorTransactions.loading === LoadingStatusEnum.Pending,
};
