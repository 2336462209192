import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import qrCodesApi from '../../api/qrCodesApi';
import { LoadingStatusEnum } from '../../constants/enums';

import { download } from '../../utils/files';

// ----------------------------------------------------------------------------

// THUNKS
export const CreateQrCodes = createAsyncThunk('qrCodes/createQrCodes', async (data, thunkAPI) => {
  const res = await qrCodesApi.create(data);

  if (res.status === 200) return { ...res.data, ...data };

  return thunkAPI.rejectWithValue();
});

export const GetQRByQrId = createAsyncThunk('qrCodes/qrId', async (params, thunkAPI) => {
  const res = await qrCodesApi.getByQrId(params);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetQRByQrIdWithoutFactory = createAsyncThunk('qrCodes/qrIdWithoutFactory', async (qrId, thunkAPI) => {
  const res = await qrCodesApi.getByQrIdWithoutFactory(qrId);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetQrCodesInfo = createAsyncThunk('qrCodes/getQrCodesInfo', async (params, thunkAPI) => {
  params.startDate = dayjs(params.startDate).format('YYYY-MM-DD');
  params.endDate = dayjs(params.endDate).format('YYYY-MM-DD');

  const res = await qrCodesApi.getInfo(params);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetQrCodesPdf = createAsyncThunk('qrCodes/getQrCodesPdf', async (data, thunkAPI) => {
  const res = await qrCodesApi.getPdf(data);

  if (res.status === 200) {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const fileName = res.headers['x-suggested-filename'];
    download(url, fileName);
  } else {
    return thunkAPI.rejectWithValue();
  }

  return null; // Add this line to return a value at the end of the function
});

export const GetBusinessCardsPdf = createAsyncThunk('qrCodes/getBusinessCardsPdf', async (data, thunkAPI) => {
  const res = await qrCodesApi.getBusinessCardsPdf(data);

  if (res.status === 200) {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const fileName = res.headers['x-suggested-filename'];
    download(url, fileName);
  } else {
    return thunkAPI.rejectWithValue();
  }

  return null; // Add this line to return a value at the end of the function
});

export const UpdateIsPrintedAndDownloaded = createAsyncThunk(
  'qrCodes/updateIsPrintedAndDownloaded',
  async (data, thunkAPI) => {
    const res = await qrCodesApi.updateIsPrintedAndDownloaded(data);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

// state
const initialState = {
  list: [],
  qr: {
    _id: null,
    createdAt: null,
    updatedAt: null,
    // props
    qrId: null,
    isDownloaded: null,
    isPrinted: null,
    type: null,
    // refs
    createdBy: null,
    cigar: null,
    employee: null,
    employeeTeam: null,
    unit: null,
    factory: null,

    loading: 'idle',
  },
  count: {
    generated: 0,
    empty: 0,
    downloaded: 0,
    printed: 0,
    used: 0,
  },
  pdfLoading: 'idle',
  loading: 'idle',
};

// slice
export const factorySlice = createSlice({
  name: 'qrCodes',
  initialState,
  reducers: {
    ClearQrCodes: () => initialState,
  },
  extraReducers: (builder) => {
    // ------------------GetQRByQrId-------------------------
    builder.addCase(GetQRByQrId.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(GetQRByQrId.fulfilled, (state, action) => ({
      ...state,
      qr: action.payload.data,
      loading: 'succeeded',
    }));

    builder.addCase(GetQRByQrId.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));
    // ------------------GetQRByQrIdWithoutFactory-------------------------
    builder.addCase(GetQRByQrIdWithoutFactory.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(GetQRByQrIdWithoutFactory.fulfilled, (state, action) => ({
      ...state,
      qr: action.payload.data,
      loading: 'succeeded',
    }));

    builder.addCase(GetQRByQrIdWithoutFactory.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));
    // ------------------GetQrCodesInfo-------------------------
    builder.addCase(GetQrCodesInfo.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(GetQrCodesInfo.fulfilled, (state, action) => ({
      ...state,
      ...action.payload.data,
      loading: 'succeeded',
    }));

    builder.addCase(GetQrCodesInfo.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));
    // ------------------GetQrCodesPdf-------------------------
    builder.addCase(GetQrCodesPdf.pending, (state) => ({
      ...state,
      pdfLoading: 'pending',
    }));

    builder.addCase(GetQrCodesPdf.fulfilled, (state) => ({
      ...state,
      pdfLoading: 'succeeded',
    }));

    builder.addCase(GetQrCodesPdf.rejected, (state) => ({
      ...state,
      pdfLoading: 'failed',
    }));
    // ------------------GetBusinessCardsPdf-------------------------
    builder.addCase(GetBusinessCardsPdf.pending, (state) => ({
      ...state,
      pdfLoading: 'pending',
    }));

    builder.addCase(GetBusinessCardsPdf.fulfilled, (state) => ({
      ...state,
      pdfLoading: 'succeeded',
    }));

    builder.addCase(GetBusinessCardsPdf.rejected, (state) => ({
      ...state,
      pdfLoading: 'failed',
    }));
    // ------------------CreateQrCodes-------------------------
    builder.addCase(CreateQrCodes.fulfilled, (state, action) => {
      const { data } = action.payload;

      return {
        ...state,
        list: [...state.list, ...data],
        count: {
          ...state.count,
          generated: state.count.generated + data.length,
          empty: state.count.empty + data.length,
        },
        loading: 'succeeded',
      };
    });
    builder.addCase(CreateQrCodes.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ----------------UpdateIsPrinted-------------------------------
    builder.addCase(UpdateIsPrintedAndDownloaded.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(UpdateIsPrintedAndDownloaded.fulfilled, (state) => ({
      ...state,
      loading: 'succeeded',
    }));

    builder.addCase(UpdateIsPrintedAndDownloaded.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));
  },
});

// Export Selectors
export const selectQrCodesCount = (state) => state.qrCodes.count;
export const selectQrCodesList = (state) => state.qrCodes.list;

export const selectQrCodeData = (state) => state.qrCodes.qr;

export const selectQrCodeLoading = (state) => state.qrCodes.loading;
export const selectQrCodeIsLoading = (state) => state.qrCodes.loading === LoadingStatusEnum.Pending;

export const selectQrCodesLoading = (state) => state.qrCodes.loading;
export const selectQrCodesIsLoading = (state) => state.qrCodes.loading === LoadingStatusEnum.Pending;

export const selectQrCodesPdfLoading = (state) => state.qrCodes.pdfLoading;
export const selectQrCodesPdfIsLoading = (state) => state.qrCodes.pdfLoading === LoadingStatusEnum.Pending;

export const { ClearQrCodes } = factorySlice.actions;

export default factorySlice.reducer;
